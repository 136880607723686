import React from "react"
import { colors } from "../../../assets/themes/default"
import styled from "@emotion/styled"

const RequiredStar = () => {
  const style = {
    color: colors.danger,
    fontWeight: `bold`,
  }

  const Wrapper = styled.span`
    ${style}
  `

  return <Wrapper>*</Wrapper>
}

export default RequiredStar
