import React from "react"
import { colors, fontSizes } from "../../../assets/themes/default"
import RequiredStar from "../RequiredStar"
import styled from "@emotion/styled"
import Text from "../Text"

const styleWrapper = {
  marginBottom: `15px`,
  minWidth: `100%`,
  textAlign: `left`,
}

const styleText = {
  color: `${colors.grayDark}`,
  marginBottom: `5px`,
}

const styleTextArea = {
  border: `1px solid ${colors.grayLight}`,
  borderRadius: `5px`,
  fontSize: `${fontSizes.normal}`,
  outline: `none`,
  padding: `5px 10px`,
  width: `100%`,
  "&:focus": {
    boxShadow: `0px 0px 2px 0px ${colors.blue}`,
  },
  "&:valid": {
    border: `1px solid ${colors.success}`,
  },
  "&:not(:placeholder-shown):invalid": {
    border: `1px solid ${colors.danger}`,
  },
}

const Wrapper = styled.div`
  ${styleWrapper}
`

const StyledText = styled(Text)`
  ${styleText}
`

const TextArea = styled.textarea`
  ${styleTextArea}
`

const InputTextArea = ({
  name,
  initialValue,
  label,
  icon,
  placeholder,
  ignoreValidationFeedback,
  isRequired,
  ...rest
}) => {
  const MIN_LENGTH = 3
  const MAX_LENGTH = 500

  return (
    <Wrapper>
      <label htmlFor={name} id={`lbl${name}`}>
        <StyledText size={fontSizes.small}>
          {label}
          {isRequired && <RequiredStar />}
        </StyledText>
        <TextArea
          name={name}
          id={name}
          required={isRequired}
          placeholder={placeholder}
          minLength={MIN_LENGTH}
          maxLength={MAX_LENGTH}
          {...rest}
        />
      </label>
    </Wrapper>
  )
}

export default InputTextArea
