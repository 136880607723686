import React from "react"
import { colors, fontSizes } from "../../../assets/themes/default"
import MaskedInput from "react-text-mask"
import RequiredStar from "../RequiredStar"
import styled from "@emotion/styled"
import Text from "../Text"

// TODO: Fix assignments using useState from React

const InputTextMasked = ({
  name,
  type,
  initialValue,
  label,
  icon,
  placeholder,
  helpText,
  mask,
  isRequired,
  ...rest
}) => {
  const MIN_LENGTH = 3
  const MAX_LENGTH = 50

  const styleWrapper = {
    marginBottom: `15px`,
    width: `100%`,
    textAlign: `left`,
  }

  const styleText = {
    color: `${colors.grayDark}`,
    marginBottom: `5px`,
  }

  const styleInput = {
    border: `1px solid ${colors.grayLight}`,
    borderRadius: `5px`,
    fontSize: `${fontSizes.normal}`,
    outline: `none`,
    padding: `5px 10px`,
    width: `100%`,
    "&:focus": {
      boxShadow: `0px 0px 2px 0px ${colors.blue}`,
    },
    "&:valid": {
      border: `1px solid ${colors.success}`,
    },
    "&:not(:placeholder-shown):invalid": {
      border: `1px solid ${colors.danger}`,
    },
  }

  const Wrapper = styled.div`
    ${styleWrapper}
  `

  const StyledText = styled(Text)`
    ${styleText}
  `

  const Input = styled(MaskedInput)`
    ${styleInput}
  `

  return (
    <Wrapper>
      <label htmlFor={name} id={`lbl${name}`}>
        <StyledText size={fontSizes.small}>
          {label}
          {isRequired && <RequiredStar />}
        </StyledText>
        <Input
          type={type}
          name={name}
          id={name}
          required={isRequired}
          placeholder={placeholder}
          value={initialValue}
          minLength={MIN_LENGTH}
          maxLength={MAX_LENGTH}
          mask={mask}
          {...rest}
        />
        {helpText.length > 0 && <Text size={fontSizes.xsmall}>{helpText}</Text>}
      </label>
    </Wrapper>
  )
}

export default InputTextMasked
