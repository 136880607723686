import React from "react"
import { colors, fontSizes } from "../../../assets/themes/default"
import RequiredStar from "../RequiredStar"
import styled from "@emotion/styled"
import Text from "../Text"

const InputSelect = ({
  name,
  type,
  values,
  initialValue,
  label,
  icon,
  placeholder,
  isRequired,
  firstOptionNotValid,
  ignoreValidationFeedback,
  ...rest
}) => {
  const styleWrapper = {
    marginBottom: `15px`,
    width: `100%`,
    textAlign: `left`,
  }

  const styleText = {
    color: `${colors.grayDark}`,
    marginBottom: `5px`,
  }

  const styleSelect = {
    border: `1px solid ${colors.grayLight}`,
    borderRadius: `5px`,
    fontSize: `${fontSizes.normal}`,
    outline: `none`,
    padding: `5px 10px`,
    width: `100%`,
    "&:focus": {
      boxShadow: `0px 0px 2px 0px ${colors.blue}`,
    },
    "&:valid": {
      border: !ignoreValidationFeedback ? `1px solid ${colors.success}` : ``,
    },
    "&:not(:placeholder-shown):invalid": {
      border: !ignoreValidationFeedback ? `1px solid ${colors.danger}` : ``,
    },
  }

  const Wrapper = styled.div`
    ${styleWrapper}
  `

  const StyledText = styled(Text)`
    ${styleText}
  `

  const Select = styled.select`
    ${styleSelect}
  `

  return (
    <Wrapper>
      <label htmlFor={name} id={`lbl${name}`}>
        <StyledText size={fontSizes.small}>
          {label}
          {isRequired && <RequiredStar />}
        </StyledText>
        <Select name={name} id={name} required={isRequired} {...rest}>
          {values.map((item, i) => {
            return (
              <option
                key={i}
                value={i === 0 && firstOptionNotValid ? "" : item}
              >
                {item}
              </option>
            )
          })}
        </Select>
      </label>
    </Wrapper>
  )
}

export default InputSelect
