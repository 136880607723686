import React from "react"
import { fontDefault, fontSizes, colors } from "../assets/themes/default"
import InputSelect from "../components/shared/InputSelect"
import InputText from "../components/shared/InputText"
import InputTextArea from "../components/shared/InputTextArea"
import InputTextMasked from "../components/shared/InputTextMasked"
import Layout from "../components/layout"
import Section from "../components/shared/Section"
import SEO from "../components/shared/SEO"
import styled from "@emotion/styled"

const styleWindow = {
  backgroundColor: `${colors.white}`,
  borderRadius: `20px`,
  boxShadow: `0px 20px 50px rgba(0, 0, 0, 0.5)`,
  width: `640px`,
  padding: `40px`,
  textAlign: `center`,
  "@media (max-width:800px)": {
    width: `90%`,
  },
}

const styleSubmitButton = {
  backgroundColor: colors.blue,
  border: `none`,
  borderRadius: `2em`,
  boxSizing: `border-box`,
  color: colors.white,
  cursor: `pointer`,
  display: `inline-block`,
  fontFamily: fontDefault,
  fontSize: fontSizes.normal,
  fontWeight: `600`,
  margin: `25px 0 0 0`,
  padding: `0.3em 1.2em`,
  textAlign: `center`,
  textDecoration: `none`,
  transition: `all 0.3s`,
  "&:hover": {
    backgroundColor: colors.blueHigh,
  },
}

const Window = styled.div`
  ${styleWindow}
`

const SubmitButton = styled.input`
  ${styleSubmitButton}
`

const SignUp = () => {
  return (
    <>
      <Layout>
        <SEO title="Get A Free Marketing Audit" keywords={[`signup`]} />
        <Section
          title="Get A Free Marketing Audit"
          color={colors.white}
          bgImage={"../images/sign-up.jpg"}
          isFullWidth
        >
          <Window>
            <form
              action="/success/"
              netlify-honeypot="first-name-008"
              data-netlify="true"
              id="signup"
              method="post"
              name="Sign Up Form"
            >
              <input type="hidden" name="form-name" value="Sign Up Form" />
              <input type="hidden" name="first-name-008" />
              <InputText
                name={`name`}
                type={`text`}
                label={`Your Name`}
                placeholder={`Your Name`}
                isRequired
              />
              <InputText
                name={`email`}
                type={`email`}
                label={`Email Address`}
                icon={`clock`}
                placeholder={`Email Address`}
                isRequired
              />
              <InputTextMasked
                name={`phone`}
                type={`tel`}
                pattern="\([0-9]{3}\) [0-9]{3} [0-9]{4}"
                mask={[
                  "(",
                  /[1-9]/,
                  /\d/,
                  /\d/,
                  ")",
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                label={`Phone`}
                placeholder={`(123) 456 7890`}
                title={`Format: (123) 456 7890`}
                helpText={`Format: (123) 456 7890`}
                isRequired
              />
              <InputText
                name={`companyName`}
                type={`text`}
                label={`Company Name`}
                placeholder={`Company Name`}
                isRequired
              />
              <InputText
                name={`companyWebsite`}
                type={`text`}
                label={`Company Website`}
                placeholder={`https://www.example.com`}
                initialValue={`https://`}
                isRequired
              />
              <InputSelect
                name={`companySize`}
                label={`Company Size`}
                values={[
                  `Please Select...`,
                  `1 - 4 Employees`,
                  `5 - 10 Employees`,
                  `11 - 50 Employees`,
                  `51 - 200 Employees`,
                  `201 - 500 Employees`,
                  `500+ Employees`,
                ]}
                firstOptionNotValid
                ignoreValidationFeedback
                isRequired
              />
              <InputSelect
                name={`estimatedMonthlyBudget`}
                label={`Estimated Monthly Budget`}
                values={[
                  `Please Select...`,
                  `Unknown`,
                  `$300 - $750/mo.`,
                  `$750 - $1,500/mo.`,
                  `$1,500 - $3,500/mo.`,
                  `$3,500 - $10,000/mo.`,
                  `$10,000+/mo.`,
                ]}
                firstOptionNotValid
                ignoreValidationFeedback
                isRequired
              />
              <InputTextArea
                name={`projectQuestions`}
                label={`Project Questions / Comments`}
                placeholder={`Project Questions / Comments`}
                ignoreValidationFeedback
              />
              <SubmitButton type="submit" />
            </form>
          </Window>
        </Section>
      </Layout>
    </>
  )
}
export default SignUp
